<template>
  <div class="min-h-full flex flex-col">
    <div>
      <div class="flex justify-between mb-10">
        <h4 class="text-3xl sm:text-4.5xl font-bold mt-1">
          Name your MINIVIEWs™
        </h4>
        <!-- Step counter -->
        <StepsCounter class="ml-8 mb-14" v-if="steps.length" :stepCount="steps.length -1" :currentStep="currentStep"/>
      </div>
    </div>

    <div class="mt-12">
      <p class="text-black font-light mb-2.5">Give your MINIVIEWs™ a Title*</p>
      <input v-model="form.title" class="form-input rounded-full focus:border-pink-500 focus:shadow-none py-3 px-4 w-full disabled:bg-guestio-gray-100 disabled:text-gray-500 mb-4">
      <span class="text-xs text-grayDark">
        *This title is for you to keep track of your MINIVIEWs™, it will not be shown to the people you send the link
      </span>
    </div>
    <div class="mt-18 flex justify-center">
      <button
        class="bg-indigo-gradiant text-white px-28 h-16 rounded-full font-bold text-xl font-roboto uppercase"
        :class="{'opacity-50 cursor-not-allowed': form.title == ''}"
        :disabled="form.title == ''"
        @click="nextStep"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState} from "vuex";
  import StepsCounter from '@/components/StepsCounter';

  export default {
    name: 'MiniviewName',
    components: { StepsCounter },

    computed: {
      ...mapGetters({
        steps: 'miniviews/steps',
      }),

      ...mapState({
        form: state => state.miniviews.form,
        currentStep: state => state.miniviews.currentStep,
      }),
    },

    methods: {
      ...mapMutations({
        incrementStep: "miniviews/incrementStep",
      }),

      nextStep() {
        this.$router.push({ name: this.steps[this.currentStep] })
        this.incrementStep();
      },
    },
  }
</script>
